import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import { useParams, Link as RouterLink, Redirect } from 'react-router-dom'

import useWidth from '../hooks/useWidth'
import useContent from '../hooks/useContent'

import ToolList from '../components/ToolList'
import Markdown from '../components/Markdown'
import Strikethrough from '../components/Strikethrough'
import VideoViewer from '../components/VideoViewer'
import AudioPlayer from '../components/AudioPlayer'

import { SoundwaveIcon } from '../icons'
import { Content } from '../types'
import { CategoryMarkdownCopy } from '*/copy.yaml'
import { ToolExerciseMarkdownContent } from '*/markdown.yaml'
import { ContentStructure } from '*/structure.yaml'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(3, 2),
      color: theme.palette.secondary.main,
    },
    image: {
      backgroundImage: ({ backgroundImage }: { backgroundImage?: string }) =>
        `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: theme.palette.primary.contrastText,
      height: 450,
      [theme.breakpoints.down('xs')]: {
        height: 300,
      },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      flex: 1,
    },
    audio: {
      marginTop: theme.spacing(2),
      width: 'max-content',
    },
    audioIcon: {
      color: theme.palette.text.secondary,
    },
    columns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    column: {
      flex: 1,
      margin: theme.spacing(3),
    },
    description: {},
    exercises: {},
    exercise: {
      marginBottom: theme.spacing(4),
    },
    divider: {
      height: 0.5,
    },
    additionalContent: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(3),
      width: '-webkit-fill-available',
    },
    tools: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(3),
    },
    toolsTitle: {
      textAlign: 'center',
    },
  }),
)

const CategoryLink: React.FC<{ id: string; content: CategoryMarkdownCopy }> = ({ id, content }) => {
  return (
    <Link to={`/${id}`} component={RouterLink}>
      {content.title}
    </Link>
  )
}

const Exercise: React.FC<{
  className?: string
  exercise: ToolExerciseMarkdownContent
  copy: { exercise: string; stop: string; challenge: string; change: string }
  small: boolean
}> = ({ className, exercise, copy, small }) => {
  const theme = useTheme()

  return (
    <Box className={className}>
      <Typography paragraph variant={small ? 'h6' : 'h4'}>
        {copy.exercise}
      </Typography>
      <Strikethrough colour={theme.palette.background.paper}>
        <Markdown content={copy.stop} variantMap={{ p: small ? 'h5' : 'h3' }} />
      </Strikethrough>
      <Markdown paragraphs content={exercise.stop} />
      <Strikethrough colour={theme.palette.background.paper}>
        <Markdown content={copy.challenge} variantMap={{ p: small ? 'h5' : 'h3' }} />
      </Strikethrough>
      <Markdown paragraphs content={exercise.challenge} />
      <Strikethrough colour={theme.palette.background.paper}>
        <Markdown content={copy.change} variantMap={{ p: small ? 'h5' : 'h3' }} />
      </Strikethrough>
      <Markdown paragraphs content={exercise.change} />
    </Box>
  )
}

interface Props {
  contentStructure: ContentStructure
  content: Content
}

const Tool: React.FC<Props> = (props) => {
  const {
    content: { general: copy, tools: toolsContent, categories: categoriesContent },
    contentStructure: { tools: toolDefinitions, categories: categoryIds },
  } = props
  const { categoryId, toolId } = useParams<{ categoryId: string; toolId: string }>()
  const { audio, images, buildMediaUrl } = useContent()
  const toolContent = toolsContent[toolId]
  const categoryContent = categoriesContent[categoryId]
  const toolDefinition = toolDefinitions.find((_toolDefinition) => _toolDefinition.id === toolId)
  const classes = useStyles({
    backgroundImage: toolContent && images[toolContent.image as keyof typeof images],
  })
  const width = useWidth()

  const titleVariant = width === 'xs' ? 'h4' : 'h2'
  const subTitleVariant = width === 'xs' ? 'h6' : 'h4'

  const otherToolIds = toolDefinitions
    .filter((d) => d.id !== toolId && (categoryId === 'tools' || d.categories.includes(categoryId)))
    .map((d) => d.id)

  if (
    toolContent === undefined ||
    toolDefinition === undefined ||
    (categoryId !== 'tools' && !toolDefinition.categories.includes(categoryId))
  ) {
    return <Redirect to="/404" />
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant={titleVariant}>
        {toolContent.title}
      </Typography>
      <Box className={classes.image}>
        {toolContent.video && (
          <VideoViewer
            className={classes.video}
            src={buildMediaUrl('video', toolContent.video.src)}
            vtt={toolContent.video.vtt}
          >
            <Typography variant="subtitle1">{copy.see}</Typography>
          </VideoViewer>
        )}
      </Box>
      {toolContent.audio && (
        <AudioPlayer src={audio[toolContent.audio as keyof typeof audio]}>
          {(onClick) => (
            <Button
              color="secondary"
              variant="contained"
              className={classes.audio}
              onClick={onClick}
              startIcon={<SoundwaveIcon className={classes.audioIcon} />}
            >
              <Typography variant="h6" color="textSecondary">
                {copy.listen}
              </Typography>
            </Button>
          )}
        </AudioPlayer>
      )}
      <Box className={classes.columns}>
        <Box className={clsx(classes.column, classes.description)}>
          <Typography paragraph variant={subTitleVariant}>
            {copy.overview}
          </Typography>
          <Markdown paragraphs content={toolContent.overview} />
          <Typography paragraph variant={subTitleVariant}>
            {copy.why}
          </Typography>
          <Markdown paragraphs content={toolContent.why} />
          <Typography paragraph variant={subTitleVariant}>
            {copy.greatFor}
          </Typography>
          <Typography component="div">
            {categoryIds
              .filter((_categoryId) => toolDefinition.categories.includes(_categoryId))
              .map((_categoryId) => (
                <CategoryLink
                  key={_categoryId}
                  id={_categoryId}
                  content={categoriesContent[_categoryId]}
                />
              ))
              .reduce<JSX.Element[]>(
                (acc, element) => [
                  ...acc,
                  element,
                  <span key={Math.random().toString(36).substring(7)}>, </span>,
                ],
                [],
              )
              .slice(0, -1)}
          </Typography>
        </Box>
        <Box className={clsx(classes.column, classes.exercises)}>
          {toolContent.exercises.map((exercise, i) => (
            <Exercise
              className={classes.exercise}
              key={i}
              exercise={exercise}
              copy={copy}
              small={width === 'xs'}
            />
          ))}
        </Box>
      </Box>
      {toolContent.additionalContent && (
        <>
          <Divider className={classes.divider} flexItem />
          <Box className={classes.additionalContent}>
            <Markdown
              paragraphs
              content={toolContent.additionalContent}
              variantMap={{
                h1: 'h5',
                h2: 'h6',
                h3: 'h6',
                h4: 'h6',
                h5: 'h6',
                h6: 'h6',
              }}
            />
          </Box>
        </>
      )}
      <Divider className={classes.divider} flexItem />
      <Box className={classes.tools}>
        <Typography className={classes.toolsTitle} paragraph variant={subTitleVariant}>
          {categoryContent.additionalTools}
        </Typography>
        <ToolList
          categoryId={categoryId}
          toolIds={otherToolIds}
          toolContents={otherToolIds.map((toolId) => toolsContent[toolId])}
        />
      </Box>
    </Box>
  )
}

export default Tool
