import React from 'react'
import { Helmet } from 'react-helmet'
import { createStyles, makeStyles, Theme, useTheme, StylesProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { createGenerateId } from 'react-jss'
import { clone } from 'ramda'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  RouteProps,
  Redirect,
} from 'react-router-dom'

import ThemeProvider from './ThemeProvider'
import anayltics from './google-analytics'

import Welcome from './pages/Welcome'
import Home from './pages/Home'
import Category from './pages/Category'
import Legal from './pages/Legal'
import Tool from './pages/Tool'

import AnimateOn from './components/AnimateOn'
import Document from './components/Document'
// import Silhouette from './components/Silhouette'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import CookieWarning from './components/CookieWarning'
import StandardPage from './components/StandardPage'

import useContent from './hooks/useContent'

const useDocumentStandardPageStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
    },
  }),
)

const useToolsStandardPageStyles = makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      padding: 0,
      overflow: 'hidden',
    },
  }),
)

const useCategoriesStandardPageStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {},
  }),
)

interface Props {
  setContent: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Routes: React.FC<Props> = ({ setContent }) => {
  const { pathname, key: innerFadeKey } = useLocation()
  const { contentStructure, images, configuration, content } = useContent()

  // initialise analytics once - with initial content config
  const analyticsInitialised = React.useRef(false)
  React.useEffect(() => {
    // do not initialise analytics when we are creating the static pages
    if (!navigator.userAgent.match(/Node\.js/i) && !analyticsInitialised.current) {
      anayltics(configuration)
      analyticsInitialised.current = true
    }
  }, [configuration])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // initialise languages once - with initial content config
  const defaultLanguageInitialised = React.useRef(false)
  React.useEffect(() => {
    // do not initialise default language when we are creating the static pages and only do it once
    if (!navigator.userAgent.match(/Node\.js/i) && !defaultLanguageInitialised.current) {
      defaultLanguageInitialised.current = true

      const preferredLanguages = navigator.languages.map((c) => c.split('-')[0].toLocaleLowerCase())

      const preferredLanguageConfiguration = configuration.availableLanguages?.find((l) =>
        preferredLanguages.includes(l.code),
      )
      preferredLanguageConfiguration && setContent(preferredLanguageConfiguration?.content)
    }
  }, [configuration, setContent])

  // get information for the standard content
  const matchParams: { [key: string]: RouteProps } = {
    welcome: { path: '/', exact: true },
    home: { path: '/home', exact: true },
    aboutPeakState: { path: '/about-peak-state', exact: true },
    aboutMentalFitness: { path: '/about-mental-fitness', exact: true },
    theScience: { path: '/the-science', exact: true },
    furtherHelp: { path: '/further-help', exact: true },
    notFound: { path: '/404', exact: true },
    legals: { path: '/legal/:legalId', exact: true },
    categories: { path: '/:categoryId', exact: true },
    tools: { path: '/:categoryId/:toolId', exact: true },
  }

  const matches = {
    welcome: useRouteMatch(matchParams.welcome),
    home: useRouteMatch(matchParams.home),
    aboutPeakState: useRouteMatch(matchParams.aboutPeakState),
    aboutMentalFitness: useRouteMatch(matchParams.aboutMentalFitness),
    theScience: useRouteMatch(matchParams.theScience),
    furtherHelp: useRouteMatch(matchParams.furtherHelp),
    notFound: useRouteMatch(matchParams.notFound),
    legals: useRouteMatch<{ legalId: string }>(matchParams.legals),
    categories: useRouteMatch<{ categoryId: string }>(matchParams.categories),
    tools: useRouteMatch<{ categoryId: string; toolId: string }>(matchParams.tools),
  }

  // for transition animations of whole page
  const navFadeKey = matches.welcome ? 'welcome' : 'other'
  const outerFadeKey = matches.welcome || matches.home ? 'home' : 'other'

  // for navigation
  const previous =
    matches.home === null
      ? matches.legals === null && matches.tools !== null
        ? {
            to: `/${matches.tools.params.categoryId}`,
            title: content.categories[matches.tools.params.categoryId].title,
          }
        : { to: `/home`, title: content.home.title }
      : undefined

  // forstandard page content
  let standardPageMediaContent: any | undefined = undefined
  if (matches.welcome !== null) {
    standardPageMediaContent = {}
  } else if (matches.home !== null) {
    standardPageMediaContent = {}
  } else if (matches.aboutPeakState !== null) {
    standardPageMediaContent = content.aboutPeakState
  } else if (matches.aboutMentalFitness !== null) {
    standardPageMediaContent = content.aboutMentalFitness
  } else if (matches.theScience !== null) {
    standardPageMediaContent = content.theScience
  } else if (matches.furtherHelp !== null) {
    standardPageMediaContent = content.furtherHelp
  } else if (matches.notFound !== null) {
    standardPageMediaContent = content.notFound
  } else if (matches.legals !== null) {
    standardPageMediaContent = content.legals[matches.legals.params.legalId]
  } else if (matches.categories !== null) {
    standardPageMediaContent =
      content.categories[matches.categories.params.categoryId] ?? content.notFound
  } else if (matches.tools !== null) {
    standardPageMediaContent = content.tools[matches.tools.params.toolId]
  }

  let standardPageClasses = useToolsStandardPageStyles()
  const documentStandardPageStyles = useDocumentStandardPageStyles()
  const categoriesStandardPageStyles = useCategoriesStandardPageStyles()
  if (
    matches.aboutPeakState !== null ||
    matches.aboutMentalFitness !== null ||
    matches.theScience !== null ||
    matches.furtherHelp !== null ||
    matches.notFound !== null ||
    matches.legals !== null
  ) {
    standardPageClasses = documentStandardPageStyles
  } else if (matches.categories !== null) {
    standardPageClasses = categoriesStandardPageStyles
  }

  const theme = useTheme()

  if (standardPageMediaContent === undefined) {
    return <Redirect to="/404" />
  }

  // for meta
  const meta = clone(content.home.meta)
  if (matches.welcome !== null) {
    // do nothing
  } else if (matches.home !== null) {
    meta.title = `${meta.title}: ${content.home.title}`
  } else {
    meta.title = `${meta.title}: ${standardPageMediaContent.title}`
  }

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>

        <meta name="description" content={meta.description} />
        <meta charSet="utf-8" />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <meta name="viewport" content="width=375, initial-scale=1" /> */}

        {/* Do not index staging sites */}
        {process.env.REACT_APP_DISABLE_ROBOTS === 'true' && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        {/* Schema.org markup for Google+ */}
        <meta itemProp="name" content={meta.title} />
        <meta itemProp="description" content={meta.description} />
        <meta itemProp="image" content={images[content.meta.socialImage as keyof typeof images]} />

        {/* Twitter Card data */}
        <meta
          name="twitter:card"
          content={images[content.meta.socialImage as keyof typeof images]}
        />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        {/* Twitter summary card with large image must be at least 280x150px */}
        <meta
          name="twitter:image:src"
          content={images[content.meta.socialImage as keyof typeof images]}
        />

        {/* Open Graph data */}
        <meta property="og:title" content={meta.title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN}${pathname}`} />
        <meta
          property="og:image"
          content={images[content.meta.socialImage as keyof typeof images]}
        />
        <meta property="og:description" content={meta.description} />
        <meta property="og:site_name" content={content.name} />

        {/* Fonts */}
        <link rel="stylesheet" href="https://use.typekit.net/ija4qon.css" />
        <link rel="stylesheet" type="text/css" href="font.css"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap"
          rel="stylesheet"
        ></link>

        {/* Icons and images */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
      </Helmet>
      {/* <ImportantMessage
        message={content.importantMessage.content}
        hideMessage={content.importantMessage.hide}
      /> */}
      <AnimateOn on={navFadeKey} backgroundColor={theme.palette.background.default} fade={true}>
        <Switch>
          <Route {...matchParams.welcome}>
            <Welcome content={content} />
          </Route>
          <Route>
            <NavBar
              transparent={false}
              previous={previous}
              contentStructure={contentStructure}
              content={content}
              homeLink={configuration.homeLink}
              setContent={setContent}
            />
            <Box role="main">
              <AnimateOn on={outerFadeKey} backgroundColor={'transparent'} fade={true} slide={true}>
                <Switch>
                  <Route {...matchParams.home}>
                    <Home contentStructure={contentStructure} content={content} />
                  </Route>
                  <Route>
                    <StandardPage
                      video={content.banner.video}
                      videoRatio={content.banner.videoRatio}
                      classes={standardPageClasses}
                    >
                      <AnimateOn
                        on={innerFadeKey}
                        backgroundColor={'transparent'}
                        fade={true}
                        slide={true}
                      >
                        <Switch>
                          <Route {...matchParams.aboutPeakState}>
                            <Document document={content.aboutPeakState} />
                          </Route>
                          <Route {...matchParams.aboutMentalFitness}>
                            <Document document={content.aboutMentalFitness} />
                          </Route>
                          <Route {...matchParams.theScience}>
                            <Document document={content.theScience} />
                          </Route>
                          <Route {...matchParams.furtherHelp}>
                            <Document document={content.furtherHelp} />
                          </Route>
                          <Route {...matchParams.notFound}>
                            <Document document={content.notFound} />
                          </Route>
                          <Route {...matchParams.legals}>
                            <Legal contentStructure={contentStructure} content={content} />
                          </Route>
                          <Route {...matchParams.categories}>
                            <Category contentStructure={contentStructure} content={content} />
                          </Route>
                          <Route {...matchParams.tools}>
                            <Tool contentStructure={contentStructure} content={content} />
                          </Route>
                        </Switch>
                      </AnimateOn>
                    </StandardPage>
                  </Route>
                </Switch>
              </AnimateOn>
              {/* <Silhouette imageIds={content.footer.silhouettes} text={content.footer.silhouette} /> */}
            </Box>
          </Route>
        </Switch>
        <Footer contentStructure={contentStructure} content={content} />
      </AnimateOn>
      <CookieWarning
        message={content.cookieWarning.content}
        hideMessage={content.cookieWarning.hide}
        readMore={{ title: content.cookieWarning.readMore, to: '/legal/cookie-policy' }}
      />
    </>
  )
}

const generateId = createGenerateId()

const App: React.FC<Props> = (props) => {
  return (
    // need to provide id generator to fix class names in static renders
    // otherwise there are clashes when the page loads and the styling is screwed
    // I don't properly understand why this issue occurs, or why this fixes it... :/
    <StylesProvider generateClassName={generateId}>
      <ThemeProvider>
        <Router>
          <Routes {...props} />
        </Router>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
