import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Link as RouterLink } from 'react-router-dom'
import { useMeasure } from 'react-use'

import Video from '../components/Video'
import Markdown from '../components/Markdown'

import useWidth from '../hooks/useWidth'
import useContent from '../hooks/useContent'

import { LogoWithText } from '../svg'
import { Content } from '../types'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      zIndex: 0,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(8, 0),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5, 0),
      },
      [theme.breakpoints.down('xs')]: {
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
      },
      minHeight: '70vh',
    },
    video: {
      position: 'absolute',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      overflow: 'hidden',
      '&>video': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    contentContainer: {
      margin: `0 0 0 50%`,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },
    },
    content: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    text: {
      marginTop: theme.spacing(1),
      maxWidth: 400,
    },
    button: {
      marginTop: theme.spacing(1),
    },
  }),
)

interface Props {
  content: Content
}

const Welcome: React.FC<Props> = (props) => {
  const { content } = props
  const classes = useStyles()
  const width = useWidth()
  const [ref, { height: videoHeight, width: videoWidth }] = useMeasure()
  const { svg, buildMediaUrl } = useContent()
  const logoWithText = svg.LogoWithText || LogoWithText

  return (
    <Box className={classes.root}>
      {width !== 'xs' && (
        <Box {...{ ref }} className={classes.video}>
          <Video
            bufferLength={5}
            height={videoWidth / videoHeight > content.start.videoRatio ? 'auto' : '100%'}
            width={videoWidth / videoHeight > content.start.videoRatio ? '100%' : 'auto'}
            src={buildMediaUrl('video', content.start.video)}
            autoPlay
            playsInline
            loop
            muted
          />
        </Box>
      )}
      <Box className={classes.contentContainer}>
        <Box className={classes.content}>
          <img width={200} src={logoWithText} alt={content.name} />
          <Markdown
            className={classes.text}
            paragraphs
            content={content.start.content}
            variantMap={{ p: 'body1' }}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            to={`/home`}
            component={RouterLink}
            size="large"
          >
            {content.general.start}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Welcome
