import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { O } from 'ts-toolbelt'
import { useMeasure } from 'react-use'

import useWidth from '../hooks/useWidth'
import useContent from '../hooks/useContent'

import { PeaksGraphic } from '../svg'
import Video from './Video'

const smallHeaderHeight = 100
const smallHeaderOverlap = 60
const headerHeight = 500
const headerOverlap = 320
const mountainContainerHeight = 220
const mountainWidth = 180

const useStyles = makeStyles<Theme, { contentHeight: number }>((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      paddingBottom: theme.spacing(5),
      position: 'relative',
      zIndex: 0,
    },
    bannerBlock: {
      backgroundColor: theme.palette.secondary.main,
      marginBottom: -headerOverlap - mountainContainerHeight,
      [theme.breakpoints.down('xs')]: {
        height: smallHeaderHeight,
        marginBottom: -smallHeaderOverlap,
      },
      position: 'relative',
      zIndex: 0,
      overflow: 'hidden',
    },
    banner: {
      width: '100%',
      height: headerHeight,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      position: 'relative',
      overflow: 'hidden',
      '&>video': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    mountainBlock: {
      position: 'relative',
      height: mountainContainerHeight,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      alignItems: 'flex-end',
    },
    mountainContainer: {
      width: 1024 + mountainWidth * 2 - theme.spacing(2),
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    preText: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      top: headerHeight - headerHeight / 1.5 - 40,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    paper: {
      width: 1024,
      maxWidth: '100%',
      margin: '0 auto',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      zIndex: 1,
      height: ({ contentHeight }) => contentHeight + 2 * theme.spacing(3),
      transition: theme.transitions.create('height', {
        duration: theme.transitions.duration.standard,
      }),
    },
  }),
)

interface Props {
  classes?: O.Optional<ClassNameMap<'root' | 'paper'>>
  video: string
  videoRatio: number
}

const StandardPage: React.FC<Props> = (props) => {
  const { classes: classesOverrides, video, children, videoRatio } = props
  const { svg, buildMediaUrl } = useContent()
  const peaksGraphic = svg.PeaksGraphic || PeaksGraphic
  const width = useWidth()
  const [contentRef, { height: contentHeight }] = useMeasure()
  const [videoRef, { height: videoHeight, width: videoWidth }] = useMeasure()
  const classes = useStyles({
    contentHeight: contentHeight < Infinity && contentHeight > 0 ? contentHeight : 1000,
  })

  return (
    <Box className={clsx(classesOverrides?.root, classes.root)}>
      <Box className={classes.bannerBlock}>
        <Box {...{ ref: videoRef }} className={classes.banner}>
          {width !== 'xs' && (
            <Video
              bufferLength={5}
              height={videoWidth / videoHeight > videoRatio ? 'auto' : '100%'}
              width={videoWidth / videoHeight > videoRatio ? '100%' : 'auto'}
              src={buildMediaUrl('video', video)}
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </Box>
        <Box className={classes.mountainBlock}>
          <Box className={classes.mountainContainer}>
            <img
              className={classes.mountain}
              width={mountainWidth}
              src={peaksGraphic}
              alt={'Mountain peaks'}
            />
            <img
              className={classes.mountain}
              width={mountainWidth}
              src={peaksGraphic}
              alt={'Mountain peaks'}
            />
          </Box>
        </Box>
      </Box>
      <Paper className={clsx(classesOverrides?.paper, classes.paper)}>
        <Box {...{ ref: contentRef }}>{children}</Box>
      </Paper>
    </Box>
  )
}

export default StandardPage
