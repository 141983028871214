import * as React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import useContent from '../hooks/useContent'

interface Props {
  setContent: React.Dispatch<React.SetStateAction<string | undefined>>
}

const LanguageSelector: React.FC<Props> = (props) => {
  const { setContent } = props
  const { configuration } = useContent()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (contentIdentifier: string) => () => {
    setContent(contentIdentifier)
    setAnchorEl(null)
  }

  return configuration.availableLanguages && configuration.language ? (
    <div>
      <Button
        color="inherit"
        aria-controls="language-selection-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {configuration.language.toUpperCase()}
      </Button>
      <Menu
        id="language-selection-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {configuration.availableLanguages.map((l) => (
          <MenuItem key={l.identifier} onClick={handleClose(l.content)}>
            {l.identifier.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : null
}

export default LanguageSelector
